
  import AddCredits from './AddCredits.vue'

  export default {
    name: `AccountingComponent`,
    components: { AddCredits },
    props: {
      api: {
        type: String,
        default: null
      },
      namespace: {
        type: String,
        default: null
      },
      name: {
        type: String,
        default: null
      },
      documentLink: {
        type: String,
        default: null
      },
      searchPlaceholder: {
        type: String,
        default: ``
      },
      headers: {
        type: Array,
        default: () => []
      },
      addCredits: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        simpleSearch: ``,
        simpleSearchRequest: null,
        exportFileName: `aeq-transaction-export.pdf`,
        minimumFractionDigits: 2
      }
    },
    async fetch() {
      await this.$store.dispatch(`accounting/getList`, { api: this.api, namespace: this.namespace })
    },
    computed: {
      list() {
        return this.$store.state.accounting.list
      },
      listForDisplay() {
        const list = this.hasNamespace() ? this.list[this.namespace] : []
        console.log(this.list, this.namespace, list)
        this.checkDigit(list)
        return this.formatDisplay(list)
      },
      headersI18n() {
        return this.headers.map((header) => ({ ...header, text: this.$t(header.text) }))
      }
    },
    watch: {
      simpleSearch: {
        deep: true,
        handler(newValue, oldValue) {
          this.delayedRequest()
        }
      }
    },
    methods: {
      _clickDownloadFiles({ documentLink = null, useApi = true }) {
        const documentLinkWithoutApi = documentLink.replace(/\/frontoffice/g, ``)
        this.$api.documents
          .getOne(documentLinkWithoutApi)
          .then((res) => {
            const filename = !!res.headers.get(`content-filename`) ? res.headers.get(`content-filename`) : `export.xlsx`
            this.$helpers.FileSaver.saveAs(new Blob([res._data]), filename)
          })
          .catch(() => {
            this.$flashMessage.error({
              title: `<${this.$options.name}> : ${this.$t(`messages.error`)}`,
              message: this.$t(`messages.cant_get_document`)
            })
          })
      },
      checkDigit(values) {
        const keyToCheck = `balance`

        const allInteger = values.every((item) => {
          const value = item[keyToCheck]
          return typeof value === `number` && Number.isInteger(value)
        })

        console.log(`allInteger`, allInteger)
        this.minimumFractionDigits = allInteger ? 0 : 2
      },
      classColorForTheSignedNumeric({ formatDateKey, [formatDateKey]: value }) {
        if (value < 0) {
          return `error--text`
        } else {
          return `green--text`
        }
      },
      formatDisplay(list = []) {
        return list.map((item) => ({
          ...item,

          balance: this.formatAccountBalance({ ...item, formatDateKey: `balance` }),
          debitCredit: this.formatDebitCredit({ ...item }),
          debitCreditColor: this.classColorForTheSignedNumeric({ ...item, formatDateKey: `debit-credit` }),
          payment_date: this.formatDate({ ...item, formatDateKey: `payment_date` }),
          creation_date: this.formatDate({ ...item, formatDateKey: `creation_date` }),
          date: this.formatDate({ ...item, formatDateKey: `date`, formatDateHour: false }),
          amount: this.formatAmount({ ...item })
        }))
      },
      formatDebitCredit({ 'debit-credit': debitCredit = 0 }) {
        return new Intl.NumberFormat(`fr-CH`, {
          minimumFractionDigits: this.minimumFractionDigits,
          maximumFractionDigits: 2
        }).format(debitCredit)
      },
      formatAmount({ amount = 0, currency = `CHF` }) {
        return new Intl.NumberFormat(`fr-CH`, {
          minimumFractionDigits: this.minimumFractionDigits,
          maximumFractionDigits: 2,
          currency,
          style: `currency`
        }).format(amount)
      },

      formatDate({ formatDateKey, [formatDateKey]: value, formatDateHour = false }) {
        const date = new Date(value)
        return !!formatDateHour && this.idDate(date)
          ? new Intl.DateTimeFormat(`fr-CH`, {
              year: `numeric`,
              month: `numeric`,
              day: `numeric`,
              hour: `numeric`,
              minute: `numeric`
            }).format(date)
          : date.toLocaleDateString()
      },
      formatAccountBalance({ formatDateKey, [formatDateKey]: value }) {
        return new Intl.NumberFormat(`fr-CH`, {
          minimumFractionDigits: this.minimumFractionDigits,
          maximumFractionDigits: 2
        }).format(value)
      },
      delayedRequest() {
        this.simpleSearchRequest && this.clearTimeoutRequest()
        this.simpleSearchRequest = setTimeout(this.getSimpleSearch, 300)
      },
      async getSimpleSearch() {
        this.loadStart()
        await this.$store.dispatch(`accounting/getList`, {
          api: this.api,
          namespace: this.namespace,
          params: { simpleSearch: this.simpleSearch }
        })
        this.loadEnd()
      },
      clearTimeoutRequest() {
        clearTimeout(this.simpleSearchRequest)
      },
      hasNamespace() {
        return typeof this.list === `object` && Object.prototype.hasOwnProperty.call(this.list, this.namespace)
      },
      idDate(date) {
        return date instanceof Date && !isNaN(date.valueOf())
      },
      loadStart() {
        this.$nextTick(() => {
          this.$nuxt.$loading.start()
        })
      },
      loadEnd() {
        this.$nextTick(function () {
          this.$nuxt.$loading.finish()
        })
      }
    }
  }
